import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { Store } from "../Store";
import validator from "validator";
import "./signup.css";

export default function SignUpScreen() {
  const [isIOS, setIsIOS] = useState(false);

  useEffect(() => {
    const userAgent = window.navigator.userAgent;
    setIsIOS(/iPhone|iPad|iPod/.test(userAgent));
  }, []);
  const navigate = useNavigate();
  const { search } = useLocation();
  const redirectURL = new URLSearchParams(search).get("redirect");
  const redirect = redirectURL ? redirectURL : "/";
  const [name, setName] = useState("");
  const [isValidName, setIsValidName] = useState(false);
  const [email, setEmail] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [password, setPassword] = useState("");
  const [isValidPassword, setIsValidPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const { dispatch: ctxDispatch } = useContext(Store);
  const [isClicked, setIsClicked] = useState(false);

  const handleClick = () => {
    setIsClicked(true);
  };

  const handleEmailChange = (event) => {
    const value = event.target.value;
    setEmail(value);
    setIsValidEmail(validator.isEmail(value) && value.length < 60);
  };

  const handlePasswordChange = (event) => {
    const value = event.target.value;
    setPassword(value);
    setIsValidPassword(validator.isStrongPassword(value));
  };

  const handleNameChange = (event) => {
    const value = event.target.value;
    setName(value);
    setIsValidName(/^[a-zA-Z\s'"]+$/.test(value) && value.length < 50);
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      Swal.fire({
        icon: "error",
        title: "Hoppla...",
        text: "Passwörter stimmen nicht überein!",
      });
      return;
    }
    if (!isValidName) {
      Swal.fire({
        icon: "error",
        title: "Hoppla...",
        text: "Ungültiger Name!",
      });
      return;
    }
    if (!isValidEmail) {
      Swal.fire({
        icon: "error",
        title: "Hoppla...",
        text: "Ungültige E-Mail-Adresse!",
      });
      return;
    }
    if (isValidEmail && isValidPassword && isValidName) {
      try {
        const { data } = await axios.post(
          "https://vapey-test.onrender.com/api/users/signup",
          {
            name,
            email,
            password,
          }
        );
        ctxDispatch({ type: "USER_SIGNIN", payload: data });
        localStorage.setItem("userInfo", JSON.stringify(data));
        navigate(redirect || "/");
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Hoppla...",
          text: "Konto existiert bereits!",
        });
      }
    }
  };

  // useEffect(() => {
  //   if (userInfo) {
  //     navigate(redirect);
  //   }
  // }, [navigate, redirect, userInfo]);

  //   const [mongoObject, setMongoObject] = useState(null);

  //   useEffect(() => {
  //     axios.get('/api/mongo/object')
  //       .then(response => {
  //         setMongoObject(response.data);
  //       })
  //       .catch(error => {
  //         console.error(error);
  //       });
  //   }, []);

  // const [users, setUsers] = useState([]);

  // useEffect(() => {
  //   axios
  //     .get("https://vapey-test.onrender.com/api/users")
  //     .then((response) => {
  //       setUsers(response.data);
  //     })
  //     .catch((error) => {
  //     });
  // }, []);
  const [inputValue, setInputValue] = useState("");

  const handleInputChange = (event) => {
    setInputValue(event.target.value.toLowerCase());
  };
  // hi
  return (
    <div>
      <Helmet>
        <title>Registrieren</title>
      </Helmet>
      <main
        className={
          isIOS ? "form-container2-ios container-responsive" : "form-container2 container-responsive"
        }
        id="signup"
      >
        <form className="form text-light mt-5" onSubmit={submitHandler}>
          <div className="row">
            <div className="mb-3 col-12">
              <label htmlFor="exampleInputEmail2" className="form-label">
                Benutzername
              </label>
              <input
                type="text"
                onChange={handleNameChange}
                className="form-control"
                id="exampleInputEmail2"
                aria-describedby="emailHelp"
              />
              {name.length === 0 && isClicked ? (
                <p className="mt-2 flash">
                  Bitte geben Sie einen Namen ein. Dieses Feld darf nicht leer
                  sein.
                </p>
              ) : name.length > 50 ? (
                <p className="mt-2 flash">
                  Bitte geben Sie einen gültigen Namen ein. Der Name muss
                  zwischen 2 und 50 Zeichen lang sein.
                </p>
              ) : isValidName && isClicked ? null : isClicked ? (
                <p className="mt-2 flash">
                  Bitte geben Sie einen gültigen Namen ein. Der Name darf nur
                  Buchstaben und Leerzeichen enthalten.
                </p>
              ) : null}
            </div>
          </div>
          <div className="mb-3">
            <label htmlFor="exampleInputEmail1" className="form-label">
              E-Mail Adresse
            </label>
            <input
              type="text"
              onChange={handleEmailChange}
              className="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              value={inputValue}
              onInput={handleInputChange}
            />
            {email.length === 0 && isClicked ? (
              <p className="mt-2 flash">
                Bitte geben Sie eine E-Mail-Adresse ein. Dieses Feld darf nicht
                leer sein.
              </p>
            ) : email.length > 60 ? (
              <p className="mt-2 flash">
                Bitte geben Sie eine gültige E-Mail-Adresse im Format
                name@Beispiel.com ein und überschreiten Sie nicht 60 Zeichen.
              </p>
            ) : isValidEmail && isClicked ? null : isClicked ? (
              <p className="mt-2 flash">
                Bitte geben Sie eine gültige E-Mail-Adresse im Format
                name@Beispiel.com ein und überschreiten Sie nicht 60 Zeichen.
              </p>
            ) : null}
          </div>
          <div className="row">
            <div className="mb-3 col-6">
              <label htmlFor="exampleInputPassword2" className="form-label">
                Passwort
              </label>
              <input
                type="password"
                onChange={handlePasswordChange}
                className="form-control"
                id="exampleInputPassword2"
              />
              {password.length === 0 && isClicked ? (
                <p className="mt-2 flash">
                  Bitte geben Sie ein Passwort ein. Dieses Feld darf nicht leer
                  sein.
                </p>
              ) : isValidPassword && isClicked ? null : isClicked ? (
                <p className="mt-2 flash">
                  Ihr Passwort muss mindestens 8 Zeichen lang sein und eine
                  Kombination aus Buchstaben (mindestens ein Großbuchstabe),
                  Zahlen und Symbolen enthalten.
                </p>
              ) : null}
            </div>
            <div className="mb-3 col-6">
              <label htmlFor="exampleInputPassword1" className="form-label">
                Passwort erneut eingeben
              </label>
              <input
                type="password"
                onChange={(e) => setConfirmPassword(e.target.value)}
                className="form-control"
                id="exampleInputPassword1"
              />
            </div>
          </div>

          <div className="sign-in-btn-container">
            <button type="submit" className="sign-in-btn" onClick={handleClick}>
              <span>Registrieren</span>
            </button>
          </div>
          <div className="mt-3 d-flex justify-content-center">
            <span className="text-light haveAcc-link">
              Sie haben bereits ein Konto ?{" "}
              <Link to={`/signin?redirect=${redirect}`} className="sign-link">
                Anmeldung
              </Link>
            </span>
          </div>
        </form>
      </main>
    </div>
  );
}
