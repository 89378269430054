import axios from "axios";
import React, { useContext, useEffect, useReducer, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate, useParams } from "react-router-dom";
import { Store } from "../Store";
import { getError } from "../utils/utils";
import { PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";
import StripeCheckout from "../components/StripeCheckout";
import Loading from "../components/loading/Loading";
import "./orderscreen.css";

function reducer(state, action) {
  switch (action.type) {
    case "FETCH_REQUEST":
      return { ...state, loading: true, error: "" };
    case "FETCH_SUCCESS":
      return { ...state, loading: false, order: action.payload, error: "" };
    case "FETCH_FAIL":
      return { ...state, loading: false, error: action.payload };
    case "PAY_REQUEST":
      return { ...state, loadingPay: true };
    case "PAY_SUCCESS":
      return { ...state, loadingPay: false, successPay: true };
    case "PAY_FAIL":
      return { ...state, loadingPay: false };
    case "PAY_RESET":
      return { ...state, loadingPay: false, successPay: false };
    case "DELIVER_REQUEST":
      return { ...state, loadingDeliver: true };
    case "DELIVER_SUCCESS":
      return { ...state, loadingDeliver: false, successDeliver: true };
    case "DELIVER_FAIL":
      return { ...state, loadingDeliver: false };
    case "DELIVER_RESET":
      return {
        ...state,
        loadingDeliver: false,
        successDeliver: false,
      };
    default:
      return state;
  }
}

export default function OrderScreen() {
  const { state } = useContext(Store);
  const { userInfo } = state;

  const params = useParams();
  const { id: orderId } = params;
  const navigate = useNavigate();
  const [payMeth] = useState(localStorage.getItem("paymentMethod"));

  const [{ loading, error, order, successPay, loadingPay }, dispatch] =
    useReducer(reducer, {
      loading: true,
      order: {},
      error: "",
      successPay: false,
      loadingPay: false,
    });
  const [{ isPending }, paypalDispatch] = usePayPalScriptReducer();

  function createOrder(data, actions) {
    return actions.order
      .create({
        purchase_units: [
          {
            amount: { value: order.totalPrice },
          },
        ],
      })
      .then((orderID) => {
        return orderID;
      });
  }

  function onApprove(data, actions) {
    return actions.order.capture().then(async function (details) {
      try {
        dispatch({ type: "PAY_REQUEST" });
        const { data } = await axios.put(
          `https://vapey-test.onrender.com/api/orders/${order._id}/pay`,
          details,
          {
            headers: { authorization: `Bearer ${userInfo.token}` },
          }
        );
        dispatch({ type: "PAY_SUCCESS", payload: data });
        alert("success");
      } catch (error) {
        dispatch({ type: "PAY_FAIL", payload: getError(error) });
        alert("err");
      }
    });
  }
  function onError(err) {
    alert("err");
  }

  useEffect(() => {
    const fetchOrder = async () => {
      try {
        dispatch({ type: "FETCH_REQUEST" });
        const { data } = await axios.get(
          `https://vapey-test.onrender.com/api/orders/${orderId}`,
          {
            headers: { authorization: `Bearer ${userInfo.token}` },
          }
        );
        dispatch({ type: "FETCH_SUCCESS", payload: data });
      } catch (error) {
        dispatch({ type: "FETCH_FAIL", payload: getError(error) });
      }
    };
    if (!userInfo) {
      return navigate("/login");
    }
    if (!order._id || successPay || (order._id && order._id !== orderId)) {
      fetchOrder();
      if (successPay) {
        dispatch({ type: "PAY_RESET" });
      }
    } else {
      const loadPaypalScript = async () => {
        const { data: clientId } = await axios.get(
          "https://vapey-test.onrender.com/api/keys/paypal",
          {
            headers: { authorization: `Bearer ${userInfo.token}` },
          }
        );
        paypalDispatch({
          type: "resetOptions",
          value: {
            "client-id": clientId,
            currency: "EUR",
          },
        });
        paypalDispatch({ type: "setLoadingStatus", value: "pending" });
      };
      loadPaypalScript();
    }
  }, [order, userInfo, orderId, navigate, paypalDispatch, successPay]);

  // useEffect(() => {
  //   const handleBeforeUnload = (event) => {
  //     console.log("Before unload event fired");
  //     event.preventDefault();
  //     event.returnValue = "";
  //   };

  //   window.addEventListener("beforeunload", handleBeforeUnload);

  //   return () => {
  //     window.removeEventListener("beforeunload", handleBeforeUnload);
  //   };
  // }, []);
  useEffect(() => {
    window.onbeforeunload = null;
  }, []);


  return loading ? (
    <div>...loading</div>
  ) : error ? (
    <div>{error}</div>
  ) : (
    <div className="pb-3 order-cont">
      <Helmet>
        <title>Bestellung {orderId} </title>
      </Helmet>

      <div className="text-light row m-5 ">
        <h1 className="color-orange display-4 fw-semibold text-center">
          Bestellung{" "}
        </h1>
        <div className="text-light text-center mb-5">
          <strong>Vorname und Nachname :</strong>{" "}
          <span>{order.shippingAddresse.fullName}</span>
          <br></br>
          <br></br>
          <strong>Addresse : </strong>{" "}
          <span>{order.shippingAddresse.address}, </span>
          <span>
            {order.shippingAddresse.city}, {order.shippingAddresse.postalCode},{" "}
            {order.shippingAddresse.country}{" "}
          </span>
        </div>
        <div className="col-md-4 col-lg-6 col-sm-12 d-flex flex-column align-items-center text-center border-- order-det">
          <div className="products-p">
            {order.orderItems.map((item, i) => (
              <div className="product-p" key={item._id}>
                <img src={item.image} alt="order_img" />
                <p className="mt-2">
                  Anzahl: <span className="prod-quant">{item.quantity}</span>
                </p>
              </div>
            ))}
          </div>
        </div>
        <div className="paypal text-light  col-lg-6 col-md-6">
          {!order.isPaid && (
            <div className="pay-cont ">
              {isPending ? (
                <Loading></Loading>
              ) : payMeth === "paypal" ? (
                <div>
                  <PayPalButtons
                    style={{
                      color: "gold",
                      shape: "rect",
                      label: "pay",
                      tagline: false,
                      height: 40,
                    }}
                    createOrder={createOrder}
                    onApprove={onApprove}
                    onError={onError}
                    fundingSource="paypal"
                  />
                </div>
              ) : (
                <div>
                  <StripeCheckout />
                </div>
              )}
              {loadingPay && <Loading />}
            </div>
          )}
          <div className="text-center">
            <div className="text-light my-2">
              {order.isDelivered ? (
                <span>LIEFERBAR {order.deliveredAt}</span>
              ) : (
                <div className="text-danger">NICHT LIEFERBAR</div>
              )}
            </div>
            <div className="text-light my-2">
              {order.isPaid ? (
                <span>BEZAHLT {order.paidAt}</span>
              ) : (
                <div className="text-danger">NICHT BEZAHLT</div>
              )}
            </div>
            <div className="text-light">
              <p>
                Produktepreis: <span className="text-info"> €</span>{" "}
                {order.itemsPrice.toFixed(2)}
              </p>

              <p className="text-light">
                Versand :<span className="text-info"> € </span>{" "}
                {order.shippingPrice.toFixed(2)}
              </p>

              <p>
                Tax : <span className="text-info"> €</span>{" "}
                {order.taxPrice.toFixed(2)}
              </p>

              <p>
                TOTAL : <span className="text-info"> € </span>{" "}
                {order.totalPrice.toFixed(2)}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
