import axios from "axios";
import React, { useContext } from "react";
import { Helmet } from "react-helmet-async";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { Store } from "../Store";
import "./panier.css";

const round2 = (num) => Math.round(num * 100 + Number.EPSILON) / 100; //123.2345 => 123.23

export default function CartScreen() {
  const navigate = useNavigate();
  const { state, dispatch: ctxDispatch } = useContext(Store);
  const {
    cart: { cartItems },
  } = state;
  const removeItemHandler = (item) => {
    ctxDispatch({ type: "CART_REMOVE_ITEM", payload: item });
  };
  const updateCartHandler = async (item, quantity) => {
    const { data } = await axios.get(
      `https://vapey-test.onrender.com/api/products/${item._id}`
    );
    if (data.countInStock < quantity) {
      Swal.fire({
        icon: "question",
        title: "Hoppla...",
        text: "Entschuldigung, das Produkt ist nicht auf Lager!",
      });
      return;
    }
    ctxDispatch({
      type: "CART_ADD_ITEM",
      payload: { ...item, quantity },
    });
  };
  const checkoutHandler = () => {
    navigate("/signin?redirect=/shipping");
  };
  return (
    <div className="container mt-5 cart-cont">
      <Helmet>
        <title>Einkaufswagen</title>
      </Helmet>
      <div className="mt-5 d-flex justify-content-between">
        <div>
          <h4 className="text-light">
            {" "}
            Gesamtpreis : €
            {round2(
              cartItems.reduce((a, c) => a + c.quantity * c.price, 0)
            )}{" "}
          </h4>
          <h6 className="text-light">
            {" "}
            Anzahl:{" "}
            <span className="text-light">
              {cartItems.reduce((a, c) => a + c.quantity, 0)} Produkt{" "}
            </span>
          </h6>
        </div>

        <div>
          {cartItems.length === 0 ? (
            <button
              id="notAllowed"
              className=" btn cart-btns btn rounded-pill"
              disabled
            >
              {" "}
              weiter zur Kasse
            </button>
          ) : (
            <button
              className="btn cart-btns btn rounded-pill"
              onClick={checkoutHandler}
            >
              {" "}
              weiter zur Kasse
            </button>
          )}
        </div>
      </div>
      <main className="d-flex justify-content-center align-items-center flex-column pb-5 px-5">
        <div className="articles">
          {cartItems.length === 0 ? (
            <div className="text-center margin-top-lg">
              <img
                src="https://cdn-icons-png.flaticon.com/128/960/960616.png"
                width={"100px"}
                alt=""
              />
              <br></br>
              <p className="text-light">
                Sie haben noch keinen Artikel ausgewählt.
              </p>
            </div>
          ) : (
            // <div className="titles row text-center">
            //   <div className="col-lg-3 col-5 ">
            //     <span>Product</span>
            //   </div>

            //   <div className="col-lg-3 col-4">
            //     <span>QUANTITY</span>
            //   </div>

            //   <div className="col-lg-1 col-3 d-flex justify-content-end">
            //     <span>
            //       <svg
            //         xmlns="http://www.w3.org/2000/svg"
            //         width="16"
            //         height="16"
            //         fill="currentColor"
            //         className="bi bi-trash3"
            //         viewBox="0 0 16 16"
            //       >
            //         <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
            //       </svg>
            //     </span>
            //   </div>
            // </div>
            <div className="titles row"></div>
          )}
          <div className="article">
            <>
              {cartItems.length === 0 ? (
                <div className="d-flex justify-content-center">
                  <Link to="/">
                    <span className="cart-btns btn rounded-3 mt-5">
                      Jetzt kaufen
                    </span>
                  </Link>
                </div>
              ) : (
                <div className="row row-cols-xl-3 row-cols-lg-2 row-cols-1">
                  {cartItems.map((item, i) => (
                    <div key={item._id}>
                      <div className="mb-4 -cart-item d-flex flex-column">
                        <div className="d-flex justify-content-evenly">
                          <div className="cart-product rounded-5">
                            <img
                              src={item.image}
                              className="cart-product-img rounded-3"
                              alt={item.slug}
                            />
                          </div>
                          <div className="d-flex align-items-center">
                            <button
                              disabled={item.quantity === 1}
                              className="btn m-1 btn-sm btn-light"
                              onClick={() =>
                                updateCartHandler(item, item.quantity - 1)
                              }
                            >
                              -
                            </button>
                            <span className="text-light mx-2">
                              {item.quantity}{" "}
                            </span>
                            <button
                              disabled={item.quantity === item.countInStock}
                              className="btn m-1 btn-sm btn-light"
                              onClick={() =>
                                updateCartHandler(item, item.quantity + 1)
                              }
                            >
                              +
                            </button>
                          </div>
                          <div className="d-flex align-items-center">
                            <button
                              className="btn btn-sm btn-danger mx-3 my-1"
                              onClick={() => removeItemHandler(item)}
                            >
                              X
                            </button>
                          </div>
                        </div>
                        <Link
                          to={`/product/${item.slug}`}
                          className="text-center text-light px-2 cart-item-link"
                        >
                          {item.name}
                        </Link>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </>
          </div>
        </div>
      </main>

      {/* <div>
        {cartItems.length === 0 ?
        <div>
            <p>empty cart</p>
        <Link to="/" >Go shopping</Link>
        </div> :
         <div>
            {cartItems.map((item)=>(
                <div key={item._id}>
                    <Link to={`/product/${item.slug}`}>{item.name}</Link>
                    <img width={'150px'} src={item.image} className="article-img" alt={item.slug} />
                    <button disabled={item.quantity === 1} onClick={()=> updateCartHandler(item,item.quantity - 1)}>
                    -
                    </button>
                    <span>{item.quantity}</span>
                    <button disabled={item.quantity === item.countInStock} onClick={()=> updateCartHandler(item,item.quantity + 1)}>
                    +
                    </button>
                    <button onClick={()=> removeItemHandler(item)}>
                    X
                    </button>
                   
                </div>
            ))}
             <div>
                        <h6> SUBTOTAL :  <span>{cartItems.reduce((a,c)=> a+ c.quantity , 0)} item </span></h6>
                        <h4> {cartItems.reduce((a,c)=> a + c.quantity * c.price , 0)} $ </h4>
                       
                    </div>
                    <div>
                       
                        <button onClick={checkoutHandler}> Procced to checkout</button>
                    </div>
        </div>
         }
        </div> */}
    </div>
  );
}
