import React, { useContext, useEffect, useReducer, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { Helmet } from "react-helmet-async";
import Message from "../components/Message";
import Loading from "../components/loading/Loading";
import { getError } from "../utils/utils";
import { Store } from "../Store";
import Magnifier from "react-magnifier";
import "./product.css";
import Swal from "sweetalert2";
import { auto } from "@popperjs/core";
import { products } from "../data";

const reducer = (state, action) => {
  switch (action.type) {
    case "FETCH_REQUEST":
      return { ...state, loading: true };
    case "FETCH_SUCCESS":
      return { ...state, product: action.payload, loading: false };
    case "FETCH_FAIL":
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export default function ProductScreen() {
  // const [isIphone, setIsIphone] = useState(false);
  // const [isIpad, setIsIpad] = useState(false);

  // useEffect(() => {
  //   const userAgent = window.navigator.userAgent;
  //   setIsIphone(/iPhone/.test(userAgent));
  // }, []);

  // useEffect(() => {
  //   const userAgent = window.navigator.userAgent;
  //   setIsIpad(userAgent.includes("iPad"));
  // }, []);

  // console.log(isIpad)

  // function isIos() {
  //   return (
  //     [
  //       "iPad Simulator",
  //       "iPhone Simulator",
  //       "iPod Simulator",
  //       "iPad",
  //       "iPhone",
  //       "iPod",
  //     ].includes(navigator.platform) ||
  //     // iPad on iOS 13 detection
  //     (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  //   );
  // }
  var isIos = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  console.log(isIos);

  const navigate = useNavigate();
  const params = useParams();
  const { slug } = params;
  const [{ loading, error, product }, dispatch] = useReducer(reducer, {
    product: [],
    loading: true,
    error: "",
  });
  useEffect(() => {
    const fetchData = async () => {
      dispatch({ type: "FETCH_REQUEST" });
      try {
        const result = await axios.get(
          `https://vapey-test.onrender.com/api/products/slug/${slug}`
        );

        dispatch({ type: "FETCH_SUCCESS", payload: result.data });
      } catch (error) {
        dispatch({ type: "FETCH_FAIL", payload: getError(error) });
      }
    };
    fetchData();
  }, [slug]);

  const { state, dispatch: ctxDispatch } = useContext(Store);
  const { cart } = state;
  const addToCartHandler = async () => {
    const existItem = cart.cartItems.find((x) => x._id === product._id);
    const quantity = existItem ? existItem.quantity + 1 : 1;
    const { data } = await axios.get(
      `https://vapey-test.onrender.com/api/products/${product._id}`
    );
    if (data.countInStock < quantity) {
      Swal.fire({
        icon: "question",
        title: "Oops...",
        text: "Sorry the product is out of stock !",
      });
      return;
    }
    ctxDispatch({
      type: "CART_ADD_ITEM",
      payload: { ...product, quantity },
    });
    navigate("/cart");
  };

  const productImages = [product.imageNoBg, product.imageBackSide];
  const [currentImage, setCurrentImage] = useState(0);
  const [heightValue, setHeightValue] = useState(0);

  useEffect(() => {
    function handleResize() {
      const width = window.innerWidth;
      if (width > 768) {
        setHeightValue(368);
      } else if (width < 768 && width > 425) {
        setHeightValue(320);
      } else if (width < 425) {
        setHeightValue(250);
      }
    }
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min);
  }
  function makeRandomSrc(x, y) {
    let i = getRandomInt(x, y);
    let src = products[i].image;
    let res = [src, i];
    return res;
  }

  let src1 = makeRandomSrc(0, 3);
  let src2 = makeRandomSrc(3, 6);
  let src3 = makeRandomSrc(6, 10);

  return loading ? (
    <Loading></Loading>
  ) : error ? (
    <Message>{error}</Message>
  ) : (
    <div className="mt-5">
      <Helmet>
        <title>{product.name}</title>
      </Helmet>
      <main className="py-5 min-vh-100 d-flex flex-column align-items-center">
        <div
          // className={
          //   isIos
          //     ? "container d-flex align-items-center flex-column mb-5"
          //     : "container d-flex flex-md-row justify-content-md-center align-items-center flex-column mb-5"
          // }
          className="container d-flex flex-lg-row justify-content-lg-center align-items-center flex-column mb-lg-5 pb-3 pb-lg-0"
        >
          {/*  : isIos && window.innerWidth > 480 ? (
            <></>
          ) */}
          {isIos ? (
            // <div class="image-container-magn">
            // <div className="ipad-img-cont">
            //   <img
            //     src={productImages[currentImage]}
            //     alt="Image"
            //     className="iPad-img"
            //   />
            // </div>
            <div className="magn-cont-ios mb-5 mb-md-0">
              <div className="magn-ios">
                <Magnifier src={productImages[currentImage]} />
              </div>
            </div>
          ) : window.innerWidth > 764 && window.innerWidth < 1030 ? (
            <div className="magn-cont-tab mb-5 mb-lg-0">
              <div className="magn-tab">
                <Magnifier src={productImages[currentImage]} />
              </div>
            </div>
          ) : window.innerWidth > 1030 && window.innerWidth < 1370 ? (
            <div className="magn-cont-tab mb-5 mb-lg-0">
              <div className="magn-tab">
                <Magnifier src={productImages[currentImage]} height={heightValue} />
              </div>
            </div>
          ) : (
            // ) : isIos ? (
            //   <div className="magn-cont-iphone mb-5 mb-md-0">
            //     <div className="magn-iphone">
            //       <Magnifier
            //         src={productImages[currentImage]}
            //       />
            //     </div>
            //   </div>
            // )
            <div className="magn-cont mb-5 mb-md-0">
              <Magnifier
                src={productImages[currentImage]}
                width={auto}
                height={heightValue}
              />
            </div>
          )}
          {/* {window.innerWidth > 768 ? (
            <div className="magn-cont">
              <Magnifier
                src={productImages[currentImage]}
                width={auto}
                height={368}
              />
            </div>
          ) : window.innerWidth < 768 && window.innerWidth > 425 ? (
            <div className="magn-cont">
              <Magnifier
                src={productImages[currentImage]}
                width={auto}
                height={320}
              />
            </div>
          ) : window.innerWidth < 425 ? (
            <div className="magn-cont">
              <Magnifier
                src={productImages[currentImage]}
                width={auto}
                height={250}
              />
            </div>
          ) : null} */}
          <div className="me-lg-5 me-md-3">
            {/* <img src="imgs/clear.png" alt /> */}
          </div>
          <div className="d-flex flex-column justify-content-evenly">
            <h2 className="text-light">{product && product.name}</h2>
            <h6 className="text-secondary">
              SMOKE -{" "}
              <span className="text-light">{product && product.category}</span>
            </h6>
            <span className="text-light display-6 fst-italic">
              {" "}
              € {product.price}
            </span>
            {/*       
          <small className="text-secondary">Quantity :</small>
          <input
            type="number"
            className="quant bg-dark text-light rounded px-2 py-1"
            min={1}
            max={50}
            id
          />
         */}
            {product.countInStock > 0 ? (
              <div className="text-success display-2">lieferbar</div>
            ) : (
              <div className="text-danger display-2">nicht lieferbar</div>
            )}
            {product.countInStock > 0 ? (
              <button
                className="btn btn-success text-center"
                onClick={addToCartHandler}
              >
                In den warenkorb
              </button>
            ) : (
              <div></div>
            )}
          </div>
        </div>
        <div className="product-sides">
          {productImages.map((image, i) => (
            <div className="side-cont" key={i}>
              <img
                src={image}
                alt="side"
                className="side-img"
                onClick={() => setCurrentImage(productImages.indexOf(image))}
              />
            </div>
          ))}
        </div>
        <div className=" w-50 text-light">
          <h2 className="mb-3 col-orng fw-semibold">
            ALLGEMEINE INFORMATIONEN
          </h2>
          <p className="mb-5 text-light">
            <span className="col-orng text-bold h3 d-block">Beschreibung </span>
            {product.description && product.description}
          </p>
          <p className="mb-3 col-orng h3">Spezifikationen</p>

          <ul className="mb-5 text-light">
            <li className="mb-2">Gewicht: 29g</li>
            <li className="mb-2">Batteriekapazität : 500mAh</li>
            <li className="mb-2"> Standby-Strom : &lt; 5uA</li>
            <li className="mb-2"> Eingangsspannung : 3.0V-4.2V</li>
            <li className="mb-2"> Ausgangsspannung : 3.6V</li>
            <li className="mb-2">Ausgangsleistung : Max 12.3W </li>
            <li className="mb-2"> Widerstand : 12Ω±0.15Ω</li>
            <li className="mb-2"> E-Liquid-Kapazität : 2ml</li>
            <li className="mb-2"> Stärke : 20mg/ml</li>
          </ul>
          <p className="mb-3 col-orng h3">Geschmacksrichtung</p>
          <p className="mb-3">{product.category && product.category}</p>
          {/* <h4 className="mb-3">Flavor</h4>
        <p className="mb-3">Clear : Menthol</p> */}
        </div>
      </main>
      {/*  */}
      <div className="also-like mb-5">
        <h2 className="mb-3">Du könntest auch mögen:</h2>
        <div className="like-products">
          <div className="like-product">
            <Link to={`/product/${products[src1[1]].slug}`}>
              <div className="info"></div>
            </Link>
            <img src={src1[0]} alt="" />
          </div>
          <div className="like-product">
            <Link to={`/product/${products[src2[1]].slug}`}>
              <div className="info"></div>
            </Link>
            <img src={src2[0]} alt="" />
          </div>
          <div className="like-product">
            <Link to={`/product/${products[src3[1]].slug}`}>
              <div className="info"></div>
            </Link>
            <img src={src3[0]} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
}
