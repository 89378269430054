import React, { useState } from "react";
import validator from "validator";
import "./footer.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import emailjs from "emailjs-com";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";

const initialState = {
  name: "",
  email: "",
  message: "",
};
export default function Footer() {
  const [setState] = useState(initialState);
  const [isClicked, setIsClicked] = useState(false);
  const [isValidName, setIsValidName] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [isValidMsg, setIsValidMsg] = useState(false);

  const handleEmailChange = (event) => {
    const value = event.target.value;
    setState(value);
    setIsValidEmail(validator.isEmail(value) && value.length < 60);
  };

  const handleMsgChange = (event) => {
    const value = event.target.value;
    setState(value);
    setIsValidMsg(value.length < 500 && value.length > 99);
  };

  const handleNameChange = (event) => {
    const value = event.target.value;
    setState(value);
    setIsValidName(/^[a-zA-Z\s'"]+$/.test(value) && value.length < 50);
  };

  const handleClick = () => {
    setIsClicked(true);
  };
  const clearState = () => setState({ ...initialState });

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!isValidName) {
      Swal.fire({
        icon: "error",
        title: "Hoppla...",
        text: "Ungültiger Name!",
      });
      return;
    }
    if (!isValidEmail) {
      Swal.fire({
        icon: "error",
        title: "Hoppla...",
        text: "Ungültige E-Mail-Adresse!",
      });
      return;
    }
    if (isValidEmail && isValidName && isValidMsg) {
      emailjs
        .sendForm(
          "service_wr3afqk",
          "template_by6yf1k",
          e.target,
          "HU_ihDoGwbMYakfSF"
        )
        .then(
          (result) => {
            Swal.fire({
              icon: "success",
              title: "E-Mail",
              text: "E-Mail erfolgreich versendet",
            });
            clearState();
          },
          (error) => {
            Swal.fire({
              icon: "error",
              title: "E-Mail",
              text: "E-Mail nicht abgeschickt",
            });
          }
        );
    }
  };
  return (
    <footer id="footerVape">
      <div className="shop-now w-100 text-center text-light py-2 d-flex flex-column justify-content-between">
        <div className="d-flex flex-column justify-content-between h-100">
          <p className="display-1 text-center my-3 ">KONTAK</p>
          <form
            name="sentMessage"
            className="container w-50 kontakt"
            // validate
            onSubmit={handleSubmit}
          >
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <input
                    type="text"
                    id="name"
                    name="name"
                    className="form-control"
                    placeholder="Name"
                    onChange={handleNameChange}
                    maxLength="50"
                  />
                  {!isValidName && isClicked ? (
                    <p className="mt-2 text-warning">Ungültiger Name!</p>
                  ) : null}
                  <p className="help-block text-danger"></p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <input
                    type="email"
                    id="email"
                    name="email"
                    className="form-control"
                    placeholder="Email"
                    onChange={handleEmailChange}
                    maxLength="100"
                  />
                  {!isValidEmail && isClicked ? (
                    <p className="mt-2 text-warning">
                      Ungültige E-Mail-Adresse!
                    </p>
                  ) : null}
                  <p className="help-block text-danger"></p>
                </div>
              </div>
            </div>
            <div className="form-group">
              <textarea
                name="message"
                id="message"
                className="form-control"
                rows="4"
                placeholder="Nachricht"
                onChange={handleMsgChange}
                maxLength="500"
                minLength="100"
              ></textarea>
              {!isValidMsg && isClicked ? (
                <p className="mt-2 text-warning">
                  Die Nachricht muss 100-500 Zeichen lang sein!
                </p>
              ) : null}
              <p className="help-block text-danger"></p>
            </div>
            <div id="success"></div>

            <button
              className="btn foot-btn"
              type="submit"
              onClick={handleClick}
            >
              KONTAK
            </button>
          </form>

          {/* <ul className="text-center">
            <li>
              <Link className="animated-arrow" to={"/flavors"}>
                <span className="the-arrow -left">
                  <span className="shaft"></span>
                </span>
                <span className="main1 fs-4 text-fx-2">
                  <span className="text">Jetzt kaufen</span>
                  <span className="the-arrow -right">
                    <span className="shaft"></span>
                  </span>
                </span>
              </Link>
            </li>
          </ul> */}
          <div></div>
        </div>
        <div className="container-fluid px-0 w-100">
          <footer className="text-center text-white">
            <div className="container p-4 pb-0">
              <section className="mb-4">
                <a
                  className="btn btn-outline-light btn-floating m-1"
                  href="#!"
                  role="button"
                >
                  <i className="bi bi-facebook"></i>
                </a>
                <a
                  className="btn btn-outline-light btn-floating m-1"
                  href="#!"
                  role="button"
                >
                  <i className="bi bi-instagram"></i>
                </a>
              </section>
            </div>
            <div className="text-center p-3">
              © 2023 Urheberrecht:{" "}
              <Link className="text-white ms-1 text-fx-2" to={"/"}>
                VapyVape
              </Link>
            </div>
          </footer>
        </div>
      </div>
      {/* <div className="bg-blackich round-b mb-5">
        <div className="desc text-light px-5 container round-b">
          <div className="opacity-50">
            <p>
              Vaporesso e-cigarette devices are intended for use with e-liquids,
              which may contain nicotine. Nicotine is an addictive chemical. Do
              not use with any other substances. Do not get on skin or in eyes.
              Do not drink. Store in original container and keep away from
              children and pets. In case of accidental ingestion, call the
              Poison Control Center at 1-800-222-1222.
            </p>
            <p>
              This product is intended for adult users of nicotine-containing
              products, particularly current smokers or vapers. Underage sale is
              prohibited. Do not use this product if you:
            </p>
            <ul>
              <li>Are under the legal age of purchase</li>
              <li>Are pregnant or breastfeeding</li>
              <li>
                Have heart disease, stomach or duodenal ulcers, liver or kidney
                problems, throat disease, or difficulty breathing due to
                bronchitis, emphysema, or asthma
              </li>
              <li>
                Have an overactive thyroid or pheochromocytoma (a tumor of the
                adrenal gland that can affect blood pressure)
              </li>
              <li>
                Are taking certain medications, such as theophylline,
                ropinirole, or clozapine
              </li>
            </ul>
            <p>
              CALIFORNIA PROPOSITION 65 - Warning: This product can expose you
              to chemicals including formaldehyde and acetaldehyde which known
              to the State of California to cause cancer, and Nicotine, which is
              known to the State of California to cause birth defects or other
              reproductive harm. For more information go to
              <a href="www.P65Warnings.ca.gov" className="desc-link">
                www.P65Warnings.ca.gov
              </a>
            </p>
          </div>
          <p>
            Copyright © 2022 Vaporesso. All rights reserved. Privacy | Terms &
            Conditions | Cookies policy
          </p>
        </div>
      </div> */}
    </footer>
  );
}
