import axios from "axios";
import React, { useContext, useEffect, useReducer } from "react";
import { Helmet } from "react-helmet-async";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Steps from "../components/steps/Steps";
import { Store } from "../Store";
import "./placeOrder.css";

const reducer = (state, action) => {
  switch (action.type) {
    case "CREATE_REQUEST":
      return { ...state, loading: true };
    case "CREATE_SUCCESS":
      return { ...state, loading: false };
    case "CREATE_FAIL":
      return { ...state, loading: false };
    default:
      return state;
  }
};

export default function PlaceOrderScreen() {
  const navigate = useNavigate();

  const [{ loading, error }, dispatch] = useReducer(reducer, {
    loading: false,
  });

  const { state } = useContext(Store);
  const { cart, userInfo } = state;
  const round2 = (num) => Math.round(num * 100 + Number.EPSILON) / 100; //123.2345 => 123.23
  cart.itemsPice = round2(
    cart.cartItems.reduce((a, c) => a + c.quantity * c.price, 0)
  );
  cart.shippingPrice = cart.cartItems.length >= 4 ? 2.99 : 1.95;
  cart.taxPrice = round2(0.19 * cart.itemsPice);
  cart.totalPrice = cart.itemsPice + cart.shippingPrice + cart.taxPrice;

  const placeOrderHandler = async () => {
    try {
      dispatch({ type: "CREATE_REQUEST" });
      const { data } = await axios.post(
        "https://vapey-test.onrender.com/api/orders",
        {
          orderItems: cart.cartItems,
          shippingAddresse: cart.shippingAddresse,
          paymentMethod: cart.paymentMethod,
          itemsPrice: cart.itemsPice,
          shippingPrice: cart.shippingPrice,
          taxPrice: cart.taxPrice,
          totalPrice: cart.totalPrice,
        },
        {
          headers: {
            authorization: `Bearer ${userInfo.token}`,
          },
        }
      );

      dispatch({ type: "CREATE_SUCCESS" });
      // ctxDispatch({type : 'CART_CLEAR'});
      // localStorage.removeItem('cartItems');
      navigate(`/order/${data.order._id}`);
    } catch (error) {
      dispatch({ type: "CREATE_FAIL" });
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Sorry , sthg wrong happend !",
      });
    }
  };

  window.onbeforeunload = function () {
    return "you can not refresh the page";
  };

  useEffect(() => {
    if (!cart.paymentMethod) {
      navigate("/payment");
    }
  }, [cart, navigate]);

  
  
  return (
    <div className="ord-scr-cont">
      <Helmet>
        <title>Bestellungsvorschau</title>
      </Helmet>
      <div className="container text-light text-center">
        <Steps step1 step2 step3 step4></Steps>

        <h1 className="text-center mt-5">Vorschaubestellung</h1>
        {/* 
        <div className="info fw-5 fs-5">
        <div className="name">
          <>Name: </span>
          <span>Someone</span>
        </div>
        <div className="adresse">
          <span>Adresse: </span>
          <span>Some Place</span>
        </div>
        <div className="email">
          <span>Email: </span>
          <span>Someone@smth.smth</span>
        </div>
      </div> */}
        <div className="text-light mb-5">
          <div className="info fw-5 fs-5">
            <div className="name">
              <span>
                Name : <span>{cart.shippingAddresse.fullName}</span>
              </span>
            </div>
            <div className="adresse">
              <span>
                Addresse :{" "}
                <span>
                  {cart.shippingAddresse.address} ,{cart.shippingAddresse.city},
                </span>
                <span>
                  {cart.shippingAddresse.postalCode},
                  {cart.shippingAddresse.country}
                </span>
              </span>
            </div>
          </div>

          {/* <p className='text-light mt-5'>Products : ({cart.cartItems.length} product)</p> */}
          <div className="products text-start container py-5">
            {/* <div className='d-flex'> */}
            {cart.cartItems.map((item, i) => (
              <div
                key={i}
                className="product mb-5 row rounded-5 d-flex justify-content-evenly"
              >
                <div className="col-2 p-img-container p-3">
                  <Link to={`/product/${item.slug}`}>
                    <img
                      src={item.imageNoBg}
                      alt={item.name}
                      className="p-img"
                    />
                  </Link>
                </div>
                <div className="details col-8">
                  <span className="categ text-light">
                    Einweg E-Zigarette Smok Novobar B600
                  </span>
                  <Link
                    to={`/product/${item.slug}`}
                    className="text-decoration-none text-light"
                  >
                    <span className="p-name">{item.name}</span>
                  </Link>
                  <div className="quant-price d-flex justify-content-between align-items-center">
                    <div className="quant rounded-pill d-flex justify-content-between row">
                      <span className="col-8">Anzahl</span>
                      <span className="bg-light col-4 rounded-pill text-dark fw-semibold text-center">
                        {item.quantity}
                      </span>
                    </div>
                    <div className="price text-end">
                      €{parseFloat((item.price * item.quantity).toFixed(3))}
                    </div>
                  </div>
                </div>
              </div>
            ))}
            {/* </div> */}
          </div>
          <Link
            to="/shipping"
            className="mx-5 text-decoration-none text-uppercase fw-semibold btn check-btn bg-orange rounded-pill py-3"
          >
            bearbeiten
          </Link>
        </div>
        <div className="total-container">
          <div className="total mb-3">
            <span>Preis der Produkte: €</span>
            <span>{cart.itemsPice.toFixed(2)}</span>
          </div>
          <div className="tax mb-3">
            <span>TAX (19%): €</span>
            <span>{cart.taxPrice.toFixed(2)}</span>
          </div>
          <div className="total mb-5">
            <span>Gesamtpreis: €</span>
            <span>{cart.totalPrice.toFixed(2)}</span>
          </div>
        </div>
        <button
          className="btn check-btn bg-orange rounded-pill py-3"
          onClick={placeOrderHandler}
          disabled={cart.cartItems.length === 0}
        >
          Bestellung aufgeben
        </button>
        <div className="ship-fee d-flex justify-content-between w-md-50 w-75 mx-auto my-5">
          <span className="fw-semibold">
            Versandkosten : €{cart.shippingPrice.toFixed(2)}
          </span>
          <div className="d-flex justify-content-around pb-3">
            <span className="lh-base">1 - 3 Stücke : €1.95</span>
            <span className="lh-base">ab 4 Stücke: €2.99</span>
          </div>
        </div>
      </div>
    </div>
  );
}
