import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./completion.css";
import { Store } from "../Store";
import { products } from "../data";

export default function Completion() {
  function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min); // The maximum is exclusive and the minimum is inclusive
  }
  function makeRandomSrc(x, y) {
    let i = getRandomInt(x, y);
    let src = products[i].image;
    let res = [src, i];
    return res;
  }

  let src1 = makeRandomSrc(0, 3);
  let src2 = makeRandomSrc(3, 6);
  let src3 = makeRandomSrc(6, 10);

  const navigate = useNavigate();

  const { state } = useContext(Store);
  const { cart } = state;
  const round2 = (num) => Math.round(num * 100 + Number.EPSILON) / 100; //123.2345 => 123.23
  cart.itemsPice = round2(
    cart.cartItems.reduce((a, c) => a + c.quantity * c.price, 0)
  );
  cart.shippingPrice = cart.cartItems.length >= 4 ? 2.99 : 1.95;
  cart.taxPrice = round2(0.19 * cart.itemsPice);
  cart.totalPrice = cart.itemsPice + cart.shippingPrice + cart.taxPrice;

  window.onbeforeunload = function () {
    return "you can not refresh the page";
  };

  useEffect(() => {
    if (!cart.paymentMethod) {
      navigate("/payment");
    }
  }, [cart, navigate]);

  const [currentDate, setCurrentDate] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(
        "https://worldtimeapi.org/api/timezone/Etc/UTC"
      );
      const data = await response.json();
      const date = new Date(data.datetime);
      const formattedDate = date.toLocaleDateString("en-GB");
      setCurrentDate(formattedDate);
    };

    fetchData();
  }, []);

  const dateString = currentDate;
  const [day, month, year] = dateString.split("/").map(Number);
  const date = new Date(year, month - 1, day);
  date.setDate(date.getDate() + 3);
  const formattedDate = `${date.getDate()}/${
    date.getMonth() + 1
  }/${date.getFullYear()}`;
  console.log(formattedDate); // Output: "17/3/2023"
// added real-time date
  return (
    <div className="thanks-cont container-responsive">
      <div className="thanks mb-5">
        <h1>DANKE FÜR IHRE BESTELLUNG {cart.shippingAddresse.fullName}</h1>
        <p className="text-light">Wir hoffen, dich bald wieder zu sehen!</p>
        <p className="text-light">
          Ihre Bestellung wird geliefert am {formattedDate}. Sie können Ihre
          Sendung verfolgen{" "}
          <Link to={"/orderhistory"} className="link">
            hier.
          </Link>
        </p>
      </div>
      <div className="also-like mb-5">
        <h2 className="mb-3">Du könntest auch mögen:</h2>
        <div className="like-products">
          <div className="like-product">
            <Link to={`/product/${products[src1[1]].slug}`}>
              <div className="info"></div>
            </Link>
            <img src={src1[0]} alt="" />
          </div>
          <div className="like-product">
            <Link to={`/product/${products[src2[1]].slug}`}>
              <div className="info"></div>
            </Link>
            <img src={src2[0]} alt="" />
          </div>
          <div className="like-product">
            <Link to={`/product/${products[src3[1]].slug}`}>
              <div className="info"></div>
            </Link>
            <img src={src3[0]} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
}
