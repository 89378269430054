import React, { useEffect, useReducer, useState } from "react";
import { getError } from "../../utils/utils";
import axios from "axios";
import logger from "use-reducer-logger";
import { banner } from "../../data";
import "./carousel2.css";
import "react-lazy-load-image-component/src/effects/blur.css";

export default function Carousel2() {
  const reducer = (state, action) => {
    switch (action.type) {
      case "FETCH_REQUEST":
        return { ...state, loading: true };
      case "FETCH_SUCCESS":
        return { ...state, products: action.payload, loading: false };
      case "FETCH_FAIL":
        return { ...state, loading: false, error: action.payload };

      default:
        return state;
    }
  };
  // const [products,setProducts] = useState([]);
  const [dispatch] = useReducer(logger(reducer), {
    products: [],
    loading: true,
    error: "",
  });
  useEffect(() => {
    const fetchData = async () => {
      dispatch({ type: "FETCH_REQUEST" });
      try {
        const result = await axios.get("https://vapey-test.onrender.com/api/products");
        dispatch({ type: "FETCH_SUCCESS", payload: result.data.products });
      } catch (error) {
        dispatch({ type: "FETCH_FAIL", payload: getError(error) });
      }
      // setProducts(result.data)
    };
    fetchData();
  }, []);

  function buttonclick2() {
    var pagebutton = document.getElementById("selfclick2");
    pagebutton.click();
  }

  return (
    <div
      className="container-responsive text-center my-5"
    >
      <div
        id="carouselExampleSlidesOnly"
        className="carousel slide carousel-fade c2"
        data-bs-ride="carousel"
      >
        <div className="carousel-inner">
          <div className="carousel-item c2-item active" data-bs-interval="5000">
            <img
              src={"banners/banner-1.png"}
              alt="banner"
              className="text-center c2-img"
            />
          </div>
          {banner.map((b) => (
            <div
              className="carousel-item c2-item"
              data-bs-interval="5000"
              key={b}
            >
              <img
                src={b}
                className="c2-img"
                alt="b600"
                onLoad={buttonclick2}
              />
            </div>
          ))}
        </div>
        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleSlidesOnly"
          data-bs-slide="prev"
        >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleSlidesOnly"
          data-bs-slide="next"
          id="selfclick2"
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
    </div>
  );
}
