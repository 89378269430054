import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Carousel1 from "./Carousel1";
import "./categories.css";

export default function Categories() {


  const [scrnSize, setIScrnSize] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setIScrnSize(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);

    // Remove event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div
      className="album w-100 round-b mb-5"
      id="categories"
    >
      <div className="d-flex flex-column flex-wrap justify-content-center align-items-center">
        <div className="mb-3 card-container d-flex justify-content-center">
          <div className="s-container">
            <Carousel1 />
          </div>
          <div className="dec-none d-flex flex-column align-items-center justify-content-between s-over">
            <div className="s-header text-center">
              <h3 className="text-fx cardTitle fw-normal mt-3 mb-3 display-4">
                B 600
              </h3>
            </div>
            {scrnSize < 992 ? (
              <div>
                <Link to={"/flavors"}>
                  <span className="text-light btn mb-4 text-fx-2 fs-4 p-2">
                    Jetzt kaufen
                  </span>
                </Link>
              </div>
            ) : (
              <ul className="text-center">
                <li>
                  <Link className="animated-arrow" to={"/flavors"}>
                    <span className="the-arrow -left">
                      <span className="shaft"></span>
                    </span>
                    <span className="main1 text-fx-2">
                      <span className="text">Jetzt kaufen</span>
                      <span className="the-arrow -right">
                        <span className="shaft"></span>
                      </span>
                    </span>
                  </Link>
                </li>
              </ul>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
