const data = {
  products: [
    {
      // _id : 2,
      name: "BLUEBERRY RASPBERRY",
      slug: "blueberry-raspberry",
      category: "BLUEBERRY",
      image: "/flavors/withBg/blueberry-raspberry.png",
      imageNoBg: "/flavors/noBg/blueberry-raspberry.png",
      imageBackSide: "/flavors/BackSide/blueberry-raspberry.png",
      price: 7.99,
      countInStock: 20,
      brand: "smoke",
      rating: 4.0,
      numReviews: 10,
      description:
        "Es fühlt sich an wie auf einem Bauernhof, wo die frischen Aromen von frisch geernteten Blaubeeren und Himbeeren in das perfekte Aroma destilliert werden.",
    },
    {
      // _id : 3,
      name: "RASPBERRY GRAPE SHERBET",
      slug: "raspberry-grape-sherbet",
      category: "RASPBERRY",
      image: "/flavors/withBg/raspberry-grape-sherbet.png",
      imageNoBg: "/flavors/noBg/raspberry-grape-sherbet.png",
      imageBackSide:
        "/flavors/BackSide/raspberry-grape-sherbet.png",
      price: 7.99,
      countInStock: 15,
      brand: "Smoke",
      rating: 4.5,
      numReviews: 14,
      description:
        "Ein erfrischender Geschmack von frisch gepflückten saftigen Trauben und Himbeeren mit einem säuerlichen, erfrischenden Sorbet.",
    },
    {
      // _id : 4,
      name: "WATERMELON ICE",
      slug: "watermelon-ice",
      category: "WATERMELON",
      image: "/flavors/withBg/watermelon-ice.png",
      imageNoBg: "/flavors/noBg/watermelon-ice.png",
      imageBackSide: "/flavors/BackSide/watermelon-ice.png",
      price: 7.99,
      countInStock: 5,
      brand: "smoke",
      rating: 4.5,
      numReviews: 10,
      description:
        "Eine Fusion aus süßem Wassermelonen-Bonbongeschmack und einer frischen Brise Menthol.",
    },
    {
      // _id : 4,
      name: "PEACH APPLE",
      slug: "peach-apple",
      category: "PEACH",
      image: "/flavors/withBg/peach-apple.png",
      imageNoBg: "/flavors/noBg/peach-apple.png",
      imageBackSide: "/flavors/BackSide/peach-apple.png",
      price: 7.99,
      countInStock: 5,
      brand: "smoke",
      rating: 4.5,
      numReviews: 10,
      description: "Ein dominanter Apfel gemischt mit süßen Pfirsichen.",
    },
    {
      // _id : 4,
      name: "PEACH GRAPE",
      slug: "peach-grape",
      category: "PEACH",
      image: "/flavors/withBg/peach-grape.png",
      imageNoBg: "/flavors/noBg/peach-grape.png",
      imageBackSide: "/flavors/BackSide/peach-grape.png",
      price: 7.99,
      countInStock: 5,
      brand: "smoke",
      rating: 4.5,
      numReviews: 10,
      description:
        "Die saftige Traube ergänzte sich hervorragend mit süßem Pfirsich.",
    },
    {
      // _id : 4,
      name: "STRAWBERRY WATERMELON",
      slug: "strawberry-watermelon",
      category: "STRAWBERRY",
      image: "/flavors/withBg/strawberry-watermelon.png",
      imageNoBg: "/flavors/noBg/strawberry-watermelon.png",
      imageBackSide: "/flavors/BackSide/strawberry-watermelon.png",
      price: 7.99,
      countInStock: 5,
      brand: "smoke",
      rating: 4.5,
      numReviews: 10,
      description:
        "Es scheint, als ob du den Geschmack des Sommers kostest, saftig und süß.",
    },
    {
      // _id : 4,
      name: "RAGING BULL",
      slug: "raging-bull",
      category: "RAGING",
      image: "/flavors/withBg/raging-bull.png",
      imageNoBg: "/flavors/noBg/raging-bull.png",
      imageBackSide: "/flavors/BackSide/raging-bull.png",
      price: 7.99,
      countInStock: 5,
      brand: "smoke",
      rating: 4.5,
      numReviews: 10,
      description:
        "Ein Ort, um Erschöpfung zu lindern und den Geist zu klären.",
    },
    {
      // _id : 4,
      name: "CHERRY LEMON PEACH",
      slug: "cherry-lemon-peach",
      category: "PEACH",
      image: "/flavors/withBg/cherry-lemon-peach.png",
      imageNoBg: "/flavors/noBg/cherry-lemon-peach.png",
      imageBackSide: "/flavors/BackSide/cherry-lemon-peach.png",
      price: 7.99,
      countInStock: 5,
      brand: "smoke",
      rating: 4.5,
      numReviews: 10,
      description:
        "Biete eine Mischung aus sauren Kirschen, saftigen Pfirsichen und frischen Zitronen an, hergestellt aus einfachen und natürlichen Zutaten.",
    },
    {
      // _id : 4,
      name: "MIXED BERRIES",
      slug: "mixed-berries",
      category: "MIXED",
      image: "/flavors/withBg/mixed-berries.png",
      imageNoBg: "/flavors/noBg/mixed-berries.png",
      imageBackSide: "/flavors/BackSide/mixed-berries.png",
      price: 7.99,
      countInStock: 5,
      brand: "smoke",
      rating: 4.5,
      numReviews: 10,
      description:
        "Überlaufend vor zuckersüßer Süße und dem Duft von Blaubeere.",
    },
    {
      // _id : 4,
      name: "BLUEBERRY BLASTING",
      slug: "blueberry-blasting",
      category: "BLUEBERRY",
      image: "/flavors/withBg/blueberry-blasting.png",
      imageNoBg: "/flavors/noBg/blueberry-blasting.png",
      imageBackSide: "/flavors/BackSide/blueberry-blasting.png",
      price: 7.99,
      countInStock: 5,
      brand: "smoke",
      rating: 4.5,
      numReviews: 10,
      description:
        "Die fruchtige Heidelbeere mildert die starke Kühle und die Letztere neutralisiert die Süße der Ersteren für einen raffinierten Geschmack.",
    },
  ],
  banner: [
    "/banners/banner-2.png",
    "/banners/banner-3.png",
    "/banners/banner-4.png",
  ],
  specs: [
    "/banners/spec-1.jpg",
    "/banners/spec-2.jpeg",
    "/banners/spec-3.jpeg",
    "/banners/spec-5.png",
    "/banners/spec-6.jpeg",
  ],
  mainBanner: "/banners/bAll.png",
};
module.exports = data;
