import "./specs.css";
import React, { useEffect, useReducer } from "react";
import { getError } from "../../utils/utils";
import axios from "axios";
import logger from "use-reducer-logger";
import { specs } from "../../data";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

export default function Specs() {
  const reducer = (state, action) => {
    switch (action.type) {
      case "FETCH_REQUEST":
        return { ...state, loading: true };
      case "FETCH_SUCCESS":
        return { ...state, products: action.payload, loading: false };
      case "FETCH_FAIL":
        return { ...state, loading: false, error: action.payload };

      default:
        return state;
    }
  };
  // const [products,setProducts] = useState([]);
  const [dispatch] = useReducer(logger(reducer), {
    products: [],
    loading: true,
    error: "",
  });
  useEffect(() => {
    const fetchData = async () => {
      dispatch({ type: "FETCH_REQUEST" });
      try {
        const result = await axios.get(
          "https://vapey-test.onrender.com/api/products"
        );
        dispatch({ type: "FETCH_SUCCESS", payload: result.data.products });
      } catch (error) {
        dispatch({ type: "FETCH_FAIL", payload: getError(error) });
      }
      // setProducts(result.data)
    };
    fetchData();
  }, []);
  return (
    <div className="spec-cont mb-5">
      {specs.map((s) => (
        <LazyLoadImage
          key={s}
          effect="blur"
          src={s}
          className="spec-img"
          alt="specification"
        />
      ))}
    </div>
  );
}
